.App {
  text-align: center;
  font-family: 'Lato', sans-serif;
}

.App-logo {
  height: 70vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  
}
